<script>
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

const isValidURL = function (event) {
  if (!event) return true
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

  return !!pattern.test(event)
}

export default {
  name: 'ToolsManage',
  components: {
    Upload: () => import('@/components/general/Upload'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    TextareaField: () => import('@/components/general/TextareaField')
  },

  data () {
    return {
      typesToolsList: [],
      themesList: [],
      formData: {
        id: null,
        idToolType: null,
        title: null,
        callText: null,
        cardImage: null,
        benefits: null,
        partnerUrl: null,
        objective: null,
        tags: null,
        active: true
      },

      backUrl: { name: 'tools.index' },
      isTitleDuplicate: false,
      objectiveInatives: []
    }
  },

  validations () {
    return {
      formData: {
        idToolType: { required },
        title: {
          required,
          titleDuplicate: () => {
            return !this.isTitleDuplicate
          }
        },

        callText: { required },
        benefits: {
          required: requiredIf(function () {
            return this.formData.idToolType !== 1
          })
        },

        cardImage: { required },
        objective: { required },

        partnerUrl: {
          required,
          validURL: isValidURL
        }
      }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id || this.formData.id
    },

    isDiagnostic () {
      return this.formData.idToolType === 1
    }
  },

  watch: {
    'formData.title' () {
      if (!this.isTitleDuplicate) return
      this.isTitleDuplicate = false
    }
  },

  created () {
    this.setup()
      .then(() => {
        if (this.$route.params.id) {
          this.setFetching(true)

          this.attemptManageTool(this.$route.params.id)
            .then(() => {
              this.formData = {
                ...this.formData,
                ...this.$store.getters.getManageTool
              }

              this.formData.idToolType = this.formData.toolType.id

              this.formData.objective = []
              this.objectiveInatives = []

              this.formData.toolTypeObjective.forEach(data => {
                if (data.theme.active) {
                  this.formData.objective.push(data.theme.alias)
                } else {
                  this.objectiveInatives.push(data.theme.alias)
                }
              })
            })
            .catch(() => {
              this.setFeedback({ message: this.$t('type_tool.load:fetch.error') })
              this.$router.push(this.backUrl)
            })
            .finally(() => {
              this.setFetching(false)
            })
        }
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptManageTool',
      'attemptSaveTool',
      'attemptUpdateTool',
      'attemptGetTypeToolsList',
      'attemptGetThemesList'
    ]),

    formatFormData () {
      const data = {}

      data.id = this.formData.id
      data.idToolType = this.formData.idToolType
      data.cardImage = this.formData.cardImage
      data.title = this.formData.title
      data.callText = this.formData.callText
      data.partnerUrl = this.formData.partnerUrl

      data.objectives = [
        ...this.formData.objective,
        ...this.objectiveInatives
      ]

      data.tags = this.formData.tags || ''
      data.active = this.formData.active

      if (!this.isDiagnostic) {
        data.benefits = this.formData.benefits
      }

      return data
    },

    submitCreation () {
      this.setFetching(true)

      return this.attemptSaveTool(this.formatFormData())
        .then(({ data }) => {
          this.formData.id = data.id
          this.setFeedback({ message: this.$t('tool:submit.create.success') })
          this.$router.push(this.backUrl)
        }).catch(({ data }) => {
          if (data.error.message === 'tool_title_already_in_use') {
            this.setFeedback({ message: this.$t('tool:submit.create.title.already.exists.error') })

            this.isTitleDuplicate = true
            this.$v.formData.slug.$touch()
          } else {
            this.setFeedback({ message: this.$t('tool:submit.create.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)

      return this.attemptUpdateTool(this.formatFormData())
        .then(() => {
          this.setFeedback({ message: this.$t('tool:submit.update.success') })
          this.$router.push(this.backUrl)
        })
        .catch(({ data }) => {
          if (data.error.message === 'tool_title_already_in_use') {
            this.setFeedback({ message: this.$t('tool:submit.create.title.already.exists.error') })

            this.isTitleDuplicate = true
            this.$v.formData.slug.$touch()
          } else {
            this.setFeedback({ message: this.$t('tool:submit.update.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit () {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          resolve(true)

          return this.isEditing ? this.submitUpdate() : this.submitCreation()
        } else {
          this.setFeedback({ message: this.$t('thematicPages:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    getTypeToolList () {
      this.attemptGetTypeToolsList({
        filter: {
          active: true
        },
        page: 1,
        limit: 100
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.data.forEach(element => {
              this.typesToolsList.push({
                text: element.title,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getThemesList () {
      return this.attemptGetThemesList({})
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.themesList.push({
                text: element.name,
                value: element.alias
              })
            })

            return true
          }
        })
    },

    setup () {
      this.setFetching(true)

      return Promise.all([
        this.getTypeToolList(),
        this.getThemesList()
      ])
        .finally(() => {
          this.setFetching(false)
        })
    }
  }
}
</script>

<template>
  <div class="main-content thematic-pages-create">
    <ContentHeader
      :title="isEditing ? formData.title : $t('tools.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.tools')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit()"
        />
      </template>
    </ContentHeader>
    <div
      class="main-content-inner"
    >
      <div class="center">
        <div class="thematic-pages-create-header">
          <p
            class="thematic-pages-create-description"
            v-html="$t('tools.create:header.description')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.title"
            :label="$t('global:form.tool.name')"
            :validation="$v.formData.title"
            :counter="100"
          />

          <SelectField
            v-model="formData.idToolType"
            tabindex="0"
            :label="$t('global:form.tool.type')"
            :items="typesToolsList"
            :validation="$v.formData.idToolType"
            :under-description="$t('global:form.tool.type.under-description')"
          />

          <InputField
            v-model="formData.callText"
            :label="$t('global:form.tracks.callText')"
            :validation="$v.formData.callText"
            :counter="250"
            :under-description="$t('global:form.tool.callText.under-description')"
          />

          <InputField
            v-if="!isDiagnostic"
            v-model="formData.benefits"
            :label="$t('global:form.tool.benefits')"
            :validation="$v.formData.benefits"
            :counter="150"
            :under-description="$t('global:form.tool.benefits.under-description')"
          />

          <InputField
            v-model="formData.partnerUrl"
            :label="$t('global:form.tool.partnerUrl')"
            :validation="$v.formData.partnerUrl"
            :counter="300"
          />

          <SelectField
            v-model="formData.objective"
            :label="$t('global:form.tool.objectives')"
            :under-description="$t('global:form.tool.objectives.under-description')"
            multiple
            :items="themesList"
            :validation="$v.formData.objective"
          />

          <FormSection :title="$t('global:form.tracks.tags')">
            <TextareaField
              v-model="formData.tags"
              :label="$t('global:form.tracks.tags')"
              :validation="$v.formData.tags"
              auto-grow
            />
          </FormSection>

          <FormSection :title="$t('global:upload.add.image.card')">
            <Upload
              v-model="formData.cardImage"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.tools.card.description')"
              cropper
              :width="385"
              :height="160"
              :preview="0.75"
              :validation="$v.formData.cardImage"
            />
          </FormSection>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.thematic-pages-create .form {
  max-width: 480px;
  margin: 0 auto;
}
.thematic-pages-create-header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.thematic-pages-create-title {
  font-family: 'Quicksand';
  font-size: 2.4em;
  font-weight: bold;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 5px;
}

.thematic-pages-create-description {
  font-family: 'Quicksand';
  font-size: 1.8em;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 0;
}

.thematic-pages-create-header + .center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

</style>
